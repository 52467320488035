.document-upload-container {
  .group:hover img {
    filter: brightness(0) invert(1);
  }
  .document-list-container {
    padding: 35px;
    position: relative;
  }
}

.document-list-container {
  padding: 35px;
}

.document-list-scroll-view {
  overflow-y: auto;
  max-height: 50vh;
  min-height: 300px;
}
