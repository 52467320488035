.document-management-container {
  .group:hover img {
    filter: brightness(0) invert(1);
  }
  .group-list-container {
    padding: 20px;
    position: relative;
  }
}

.group-list-scroll-view {
  overflow-y: auto;
  max-height: 80vh;
  min-height: 300px;
}
