@import '../../../../../shared//src/assets/styles/_variables';

.DropdownWrapper {
  position: relative;
}

.DropdownLabel {
  font-family: $font-family;
  font-size: 0.875rem;
  font-weight: 500;

  .DropdownLabelRequiredAsterisk {
    margin-left: $spacing-1;
    color: #c7200a;
  }
}
