@import '../../../../../shared//src/assets/styles/_variables';

// .InputLabelRequiredAstrisk {
//   margin-left: $spacing-1;
//   color: red
// }

.InputWrapper {
  position: relative;
}

.InputLabel {
  font-family: $font-family;
  font-size: 0.875rem;
  font-weight: 500;

  .InputLabelRequiredAsterisk {
    margin-left: $spacing-1;
    color: #c7200a;
  }
}
